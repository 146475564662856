import React from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"

import { Img } from "./Img"

import * as styles from "./yourHalvaQrBanner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

type HalvaQrBannerProps = {
  orderNum: string
}

export default function YourHalvaQrBanner({ orderNum }: HalvaQrBannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Img className={styles.img} alt="bg image" />
      <Container className={styles.container}>
        <div className={styles.textBlock}>
          <h1>
            Заполните заявку
            <br /> на&nbsp;бесплатную карту «Халва»!
          </h1>
          <ul>
            <li>
              – Вы в отделении Совкомбанка?
              <br className={styles.brMobTab} /> Тогда выберите «Заявка в офисе»
            </li>
            <li>– Если вы находитесь в другом месте — выберите «Онлайн заявка»</li>
          </ul>
        </div>
        <div className={styles.btnsGroup}>
          <Button
            className={styles.btnInGroup}
            onClick={() => handleClickBanner("click_cta", true)}
          >
            <a
              className={styles.linkAtBtn}
              target="_blank"
              href="https://anketa.sovcombank.ru/"
              rel="noreferrer"
            >
              Заявка в офисе
            </a>
          </Button>
          <Button
            className={clsx(styles.btnInGroup, styles.btnForm)}
            onClick={() => handleClickBanner("click_cta")}
          >
            Онлайн заявка
          </Button>
        </div>
      </Container>
    </section>
  )
}
