// extracted by mini-css-extract-plugin
export var brMobTab = "yourHalvaQrBanner-module--brMobTab--P431U";
export var btn = "yourHalvaQrBanner-module--btn--aTtvd";
export var btnForm = "yourHalvaQrBanner-module--btnForm--pKbNO";
export var btnInGroup = "yourHalvaQrBanner-module--btnInGroup--l7obT";
export var btnLink = "yourHalvaQrBanner-module--btnLink--fKE0a";
export var btnsGroup = "yourHalvaQrBanner-module--btnsGroup--5uTzF";
export var container = "yourHalvaQrBanner-module--container--GiiHS";
export var img = "yourHalvaQrBanner-module--img--HoGij";
export var linkAtBtn = "yourHalvaQrBanner-module--linkAtBtn--Iu3gf";
export var section = "yourHalvaQrBanner-module--section--CbKZi";
export var textBlock = "yourHalvaQrBanner-module--textBlock--obZes";